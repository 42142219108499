/**
 * ハンバーガーメニュー
 */

document.addEventListener('DOMContentLoaded', function () {
  let scrollPos;
  const body = document.querySelector('body');
  const hamburger = document.querySelector('.js-hamburger');
  const drawer = document.querySelector('.js-drawer');
  const focusTrap = document.querySelector('.js-focus-trap');
  const gnavLinks = document.querySelectorAll('.c-gnav a');

  // クリックで開閉
  hamburger.addEventListener('click', function () {
    if (this.getAttribute('aria-expanded') === 'false') {
      openDrawer(this);
    } else {
      closeDrawer(this);
    }
  });

  // メニュークリックで閉じる
  gnavLinks.forEach((link) => {
    link.addEventListener('click', function () {
      if (hamburger.getAttribute('aria-expanded') === 'true') {
        closeDrawer(hamburger);
      }
    });
  });

  // リサイズ時の処理
  let flag;
  if (window.matchMedia('(min-width: 1025px)').matches) {
    flag = 'pc';
  } else {
    flag = 'sp';
  }
  window.addEventListener('resize', function () {
    if (window.matchMedia('(min-width: 1025px)').matches && flag !== 'pc') {
      closeDrawer(hamburger);
      flag = 'pc';
    } else if (flag !== 'sp') {
      closeDrawer(hamburger);
      flag = 'sp';
    }
  });

  function openDrawer(thisHamburger) {
    scrollPos = window.pageYOffset;
    drawer.style.display = 'block';
    drawer.setAttribute('aria-hidden', 'false');
    drawer.focus();
    thisHamburger.setAttribute('aria-expanded', 'true');
    thisHamburger.setAttribute('aria-label', '閉じる');
    body.classList.add('is-fixed');
    body.style.top = -scrollPos + 'px';
  }

  function closeDrawer(thisHamburger) {
    drawer.style.display = 'none';
    drawer.setAttribute('aria-hidden', 'true');
    thisHamburger.setAttribute('aria-expanded', 'false');
    thisHamburger.setAttribute('aria-label', 'メニューを開く');
    hamburger.focus();
    body.classList.remove('is-fixed');
    body.style.top = '';
    document.documentElement.style.scrollBehavior = 'auto';
    window.scrollTo(0, scrollPos);
    document.documentElement.style.scrollBehavior = '';
  }

  // フォーカストラップ制御
  focusTrap.addEventListener('focus', function () {
    hamburger.focus();
  });

  document.addEventListener('keydown', function (e) {
    if (e.key === 'Escape') {
      closeDrawer(hamburger);
    }
  });
});
