document.addEventListener('DOMContentLoaded', function () {
  const copyElement = document.querySelector('.p-mainvisual__copy');
  const lines = copyElement.querySelectorAll('.__line');

  lines.forEach((line, index) => {
    line.style.setProperty('--slide-delay', `${0.1 + index * 0.1}s`); // スライドインの遅延
  });

  // アニメーションを開始する
  requestAnimationFrame(() => {
    copyElement.classList.add('is-animated');
  });
});
