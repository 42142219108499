document.addEventListener('DOMContentLoaded', function () {
  const stickyElement = document.querySelector('.p-header__inner');
  const header = document.querySelector('.l-header');
  let isSticky = false;

  function checkSticky() {
    const top = stickyElement.getBoundingClientRect().top;
    if (top === 0) {
      if (!isSticky) {
        header.classList.add('is-sticky');
        isSticky = true;
      }
    } else {
      header.classList.remove('is-sticky');
      isSticky = false;
    }
  }

  window.addEventListener('scroll', checkSticky);
  checkSticky();
});
